import * as csvParser from 'papaparse';
import uniqBy from 'lodash/uniqBy';


export const parseCsvV1 = (files, validateCsv, headerToCheckEmpty = []) => {
    if (files && files.length > 0) {
        let file = files[0];
        csvParser.parse(file, {
            header: true,
            keepEmptyRows: false,
            skipEmptyLines: true,
            trimHeaders: true,
            transform: (value, header) => {
                if (headerToCheckEmpty.includes(header) && !value) {
                    return undefined;
                }
                return value;
            },
            complete: data => {
                validateCsv(data, null);
            },
            error: err => {
                validateCsv(null, err);
            }
        });
    }
};

export const getCSVValidationErrors = ajvErrors => {
    ajvErrors = ajvErrors || [];
    let mErrors = [];

    let typeErrors = ajvErrors
        .filter(error => error.keyword === 'type')
        .map(error => {
            let temp = {};
            let jsonInfo = error.dataPath.split('/');
            const key = jsonInfo.pop();
            const index = 1 + +jsonInfo.pop();
            temp.message = `${key} field at row ${index} ${error.message}`;
            return temp;
        });

    let requiredErrors = ajvErrors.filter(
        error => error.keyword === 'required'
    );
    requiredErrors = uniqBy(requiredErrors, 'message').map(error => {
        let temp = {};
        temp.message = `column ${error.params.missingProperty} is missing`;
        return temp;
    });

    let otherErrors = ajvErrors
        .filter(
            error => error.keyword !== 'required' && error.keyword !== 'type'
        )
        .map(({ message }) => ({ message }));

    mErrors = [...typeErrors, ...requiredErrors, ...otherErrors];
    console.log(mErrors);
    return mErrors;
};
