<template>
    <div class="page-error">
        <img src="../../assets/empty_state.png" alt="" />
        <div
            class="page-error-text"
            alt="No content"
        >
            <span class="dark-sm cl-DustyGray2">{{ helperText }}</span>
        </div>
        <div v-if="btnText">
            <nitrozen-button
                theme="secondary"
                v-flatBtn
                @click="onTryAgain()"
            >{{ btnText }}
            </nitrozen-button>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import "../../less/theme.less";
@import "../../less/media.less";
@import "../../less/text.less";
@import "../../less/color.less";
@import "../../less/page-header.less";
.page-error {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 5%;
    img {
        width: 400px;
        height: 300px;
    }
    .page-error-text {
        color: @Mako;
        span {
            display: block;
            margin: 24px;
        }
    }
}
</style>

<script>
import { NitrozenButton, flatBtn } from "@gofynd/nitrozen-vue";

export default {
    name: "adm-no-content",
    components: {
        "nitrozen-button": NitrozenButton,
    },
    directives: {
        flatBtn,
    },
    props: {
        helperText: {
            type: String,
            default: "No content present",
        },
        btnText: {
            type: String,
            default: "",
        },
    },
    methods: {
        onTryAgain() {
            this.$emit("tryAgain");
        },
    },
};
</script>
