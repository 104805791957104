<template>
  <div class="page_body">
    <ext-bridge v-on:hintChanged="hintChanged"></ext-bridge>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>
    <e-funnel-card
      :title="'Product Funnel'"
      :funnelCards="funnelCards"
      :statsData="statsData"
    />

    <div :class="['table-container']">
      <div class="table-container-top">
        <div>
          <p class="product-map-txt">Product</p>
          <p class="product-map-desc">
            Add SKU of your {{ this.marketplaceName }} products with those on Fynd Platform
            respectively and publish them on {{ this.marketplaceName }}
          </p>
        </div>
        <div
          :class="['bulk-action-btn', { 'bulk-action-bg': isBulkActionOpen }]"
        >
          <nitrozen-button
            :disabled="
              !(
                isEmpty(lastJobData) ||
                lastJobData.status == 'completed' ||
                lastJobData.status == 'failed'
              )
            "
            theme="secondary"
            v-flatBtn
            @click="
              () => {
                isBulkActionOpen = !isBulkActionOpen;
              }
            "
            >{{ !isBulkActionOpen ? "Bulk Actions" : "Cancel" }}
          </nitrozen-button>
        </div>
      </div>
      <div v-if="isBulkActionOpen" class="table-container-bulk-action">
        <div class="bulk-action-title">Bulk Product Mapping</div>
        <p class="bulk-action-sub-title">
          Follow these steps to map multiple products in bulk
        </p>
        <div class="bulk-action-cards">
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              1.Use the given<strong> Filters</strong> below and click the
              <strong>Download</strong> button to get a CSV file containing all the
              products
            </p>
            <nitrozen-dropdown
              label="Status"
              :items="statusList"
              v-model.trim="selectedStatusForDownlaod"
            >
            </nitrozen-dropdown>
            <nitrozen-dropdown
              label="Brand"
              :items="brandValuesList"
              class="status-filter"
              v-model.trim="selectedBrandForDownload"
            >
            </nitrozen-dropdown>
            <nitrozen-button
              theme="secondary"
              v-flatBtn
              @click="downloadSKUMapping"
              class="bulk-action-card-btn"
            >
              Download
            </nitrozen-button>
          </div>
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              <strong>2. Open</strong> CSV file and follow these steps
            </p>
            <p class="bulk-action-card-desc">
              1. Add SKUs of {{ this.marketplaceName }} products in the <strong>{{ this.marketplaceName }} SKU</strong> column
            </p>
            <p class="bulk-action-card-desc">
              2. In the <strong>Status</strong> column, type 'active' for all the products
              you wish to publish, otherwise 'inactive' for not making the
              product LIVE
            </p>
          </div>
          <div class="bulk-action-card">
            <p class="bulk-action-card-title">
              <strong>3. Upload</strong> the updated CSV file after filling all the
              required details
            </p>
            <div
              class="drag-drop-block"
              @drop.prevent.stop="onDrop"
              @dragenter.prevent.stop="highlight = true"
              @dragover.prevent.stop="highlight = true"
              @dragleave.prevent.stop="highlight = false"
            >
              <inline-svg
                :src="'upload_cloud'"
                class="feature-icon"
              ></inline-svg>
              <p class="drag-drop-text">Drag and drop your file here</p>
              <p class="drag-drop-text">OR</p>
            </div>
            <nitrozen-button
              theme="secondary"
              v-strokeBtn
              @click="
                () => {
                  $refs.fileUploader.click();
                }
              "
              class="bulk-update-card-btn"
              >Upload
            </nitrozen-button>
            <input
              ref="fileUploader"
              type="file"
              accept=".csv"
              @change="onFileRead($event.target.files)"
            />
          </div>
        </div>
      </div>
      <strip-prod-comp
        :isLastJobBlockOpen="isLastJobBlockOpen"
        :lastJobData="lastJobData"
        @handleCancel="cancelJobs($event)"
      />

      <div class="preview-block" v-show="showCSVPreview">
        <div class="preview-block-top">
          <div class="preview-block-title">SKU Mapping Preview (Top 5)</div>
          <nitrozen-button
            v-strokeBtn
            class="cancel-map-btn"
            theme="secondary"
            @click="cancelUploadProductMapping"
          >
            Cancel</nitrozen-button
          >
          <nitrozen-button
            :disabled="csvError"
            v-flatBtn
            class="proceed-map-btn"
            theme="secondary"
            @click="proceedUploadProductMapping"
            >Proceed</nitrozen-button
          >
        </div>
        <div class="preview-strip-error" v-if="csvError">
          <inline-svg
            :src="'warning'"
            class="feature-icon preview-error-icon"
          ></inline-svg>
          <p class="preview-error-txt">
            Failed to process SKU Mapping! Please download the error file to
            check and resolve errors. Total rows with error are
            <strong>{{ csvErrorCount }}</strong>
          </p>
          <nitrozen-button
            v-flatBtn
            class="preview-error-btn"
            theme="secondary"
            @click="downloadErrorReport"
            >Download error report</nitrozen-button
          >
        </div>
        <div
          class="csv-preview-diag"
          ref="csv-preview-diag"
          :title="csvPreviewTitle"
        >
          <csv-previewer ref="csv-preview" css="height:300px;"></csv-previewer>
        </div>
      </div>

      <div class="input-box">
        <div class="search">
          <nitrozen-input
            type="search"
            :placeholder="searchPlaceholderText"
            :showSearchIcon="true"
            v-model.trim="searchSKUText"
            @input="debouncedSearch"
            :showSuffix="true"
            :custom="true"
          >
            <nitrozen-dropdown
              class="identifier-suffix-2"
              :items="productIdentifiers"
              v-model.trim="productIdentifier"
              @input="
                () => {
                  resetPagination();
                  getMappedSkuList();
                }
              "
            />
          </nitrozen-input>
        </div>
        <div>
          <nitrozen-dropdown
            label="Brand"
            class="status-filter"
            :items="brandValuesList"
            v-model.trim="selectedBrandFilter"
            @input="
              () => {
                resetPagination();
                getMappedSkuList();
              }
            "
            :searchable="true"
            @searchInputChange="setBrandValuesList"
          >
          </nitrozen-dropdown>
        </div>
        <div>
          <nitrozen-dropdown
            label="Status"
            :items="statusList"
            class="status-filter"
            @change="
              () => {
                resetPagination();
                getMappedSkuList();
              }
            "
            v-model.trim="selectedStatus"
          >
          </nitrozen-dropdown>
        </div>
      </div>

      <product-table
        :skuList="skuList"
        :headers="headers"
        :hintSteps="hintSteps"
        :doneToNext="doneToNext"
        :isHintActive="isHintActive"
        :dontShowAgain="dontShowAgain"
        :showAllProducts="showAllProducts"
        @editProduct="editProduct($event)"
        :currentHintStage="currentHintStage"
        @fetchPagination="getMappedSkuList($event)"
        @hintInactive="setHintInactive"
        :pagination="pagination"
        @handleHinting="handleHintFinished($event)"
        @handlingPrimaryEvt="handlePrimaryEvt($event)"
      />
      <adm-no-content
        v-if="!skuList.length && !inProgress"
        helperText="No products found"
        class="no-content"
      >
      </adm-no-content>
    </div>
    <loader class="loading" v-if="inProgress"></loader>
  </div>
</template>

<script>
import {
  NitrozenButton,
  flatBtn,
  strokeBtn,
  NitrozenInput,
  NitrozenDropdown,
} from "@gofynd/nitrozen-vue";
import EXT from "../extension_bridge";
import extBridge from "./ext-bridge.vue"; // eslint-disable-line no-unused-vars
import AdminMarketplacesService from "@/services/main.service";
import { parseCsvV1 } from "@/helper/csv-parser.helper.js";
import loader from "@/components/common/adm-loader";
import csvPreviwer from "@/components/common/adm-csv-viewer.vue";
import InlineSvg from "@/components/common/inline-svg";
import moment from "moment";
import { debounce, getCompanyBasePath } from "@/helper/utils";
import admNoContent from "@/components/common/adm-no-content.vue";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import { MARKETPLACE_NAME } from "@/helper/constant.js";
import { saveAs } from "file-saver";
import * as csvParser from "papaparse";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import isArray from "lodash/isArray";
import {
  ExtensionFunnelCard,
  ExtensionProdStrip,
  ExtensionProductTable,
} from "marketplace-utilities/web";
const HEADERS = [
  {
    title: "Seller Identifier",
    type: "text",
    key: "seller_identifier",
  },
  {
    title: "Size",
    key: "size",
    type: "text",
  },
  {
    title: `${MARKETPLACE_NAME} SKU`,
    key: "marketplace_identifiers",
    identifier : "sku",
    type: "text",
  },
  {
    title: "Action",
    type: "action",
  },
];
const EXTRA_HEADERS_FIELDS = {
  extraFields: [
    {
      title: `${MARKETPLACE_NAME} SKU`,
      key: "marketplace_identifiers",
      identifier : "sku",
      type: "text",
    },
    {
      title: "Product Vertical",
      key: "marketplace_identifiers",
      identifier : "vertical",
      type: "text",
    },
    {
      title: "Status",
      type: "badge",
      key: "active",
    }
  ],
  schema: {
    [`${MARKETPLACE_NAME} SKU`]: { type: "string" },
    Status: { type: "boolean" },
  },
};

const MAX_POLLING_COUNTER = 100;
const BATCH_SIZE = 2000;
const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};

const ARTICLE_IDENITIFER = [
  {
    key: "upc",
    name: "UPC",
    is_active: true,
  },
  {
    name: "EAN",
    key: "ean",
    is_active: true,
  },
  {
    name: "SKU Code",
    key: "sku_code",
    is_active: true,
  },
];

const MKP_IDENTIFIERS = [
  {
    key: MARKETPLACE_NAME.toLowerCase(),
    name: MARKETPLACE_NAME.toLowerCase(),
    active: true,
    identifiers: ["sku"],
  },
];

const NAVS = [
  {
    title: "Home",
    link: "",
  },
  { title: "Product" },
];

const FUNNEL_CARDS = [
  {
    title: "Total Products",
    help: "",
    model: "total",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Mapped Products",
    help: "",
    model: "mapped",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Active Products",
    help: "",
    model: "active",
    type: "box",
  },
];

const SELLER_IDENTIFIERS = ["Item Code", "Season", "Category", "Status", "Brand Id", "Item Id", "Size", "size"]

export default {
  name: "sku-mapped-product-list",
  components: {
    "nitrozen-button": NitrozenButton,
    "ext-bridge": extBridge,
    "nitrozen-input": NitrozenInput,
    "csv-previewer": csvPreviwer,
    // "nitrozen-badge": NitrozenBadge,
    "adm-no-content": admNoContent,
    NitrozenDropdown,
    loader,
    "inline-svg": InlineSvg,
    "custom-breadcrumb": breadCrumb,
    "e-funnel-card": ExtensionFunnelCard,
    "strip-prod-comp": ExtensionProdStrip,
    "product-table": ExtensionProductTable,
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  data() {
    return {
      marketplaceName : MARKETPLACE_NAME,
      showAllProducts: [],
      navs: [],
      isBulkActionOpen: false,
      isLastJobBlockOpen: true,
      lastJobData: {},
      showCSVPreview: false,
      csvError: false,
      doneToNext: false,
      dontShowAgain: true,
      hintsteps: [],
      csvErrorData: {},
      uploadedCSVData: {},
      csvErrorCount: 0,
      funnelCards: [],
      searchText: "",
      productIdentifier: "channel_identifier",
      productIdentifiers: [
        {
          text: `${MARKETPLACE_NAME} SKU`,
          value: "channel_identifier",
        },
        {
          text: "Seller Identifier",
          value: "seller_identifier",
        },
        {
          text: "Product name",
          value: "product_name",
        },
      ],
      isActive: false,
      inProgress: false,
      loaderHelperText: "",
      mkpIdentifiers: [],
      articleIdentifiers: [],
      brands: [],
      selectedBrand: "",
      downloadJobPollId: "",
      skuList: [],
      articleIdfsInItemsList: [],
      mkpIdfsInItemsList: [],
      editItemUid: "",
      editItem: {},
      processSkuBatches: "",
      jobId: "",
      pagination: cloneDeep(PAGINATION),
      products: [],
      searchProductPagination: {},
      searchSelectedUid: "",
      productsLoading: false,
      pageLoading: false,
      csvPreviewTitle: "",
      articlesPresent: false,
      isHintActive: false,
      currentHintStage: 0,
      // doing this different api need different mkp name format
      mappingsErrors: [],
      interval: 3000,
      selectedBrandForDownload: [],
      selectedBrandFilter: "",
      brandValuesList: [],
      isInitialLoad: false,
      searchSKUText: "",
      searchPlaceholderText: "",
      statusList: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Active",
          value: "true",
        },
        {
          text: "Inactive",
          value: "false",
        },
      ],
      statsData: {
        total: 0,
        mapped: 0,
        active: 0,
      },

      selectedStatus: "all",
      selectedStatusForDownlaod: "all",
      headers: [],
      verticals : ""
    };
  },
  mounted() {
    this.pagination.current = 1;
    this.pagination.limit = 10;
    //extract article identifiers
    AdminMarketplacesService.getVerticals().then(resp =>{
        this.verticals = resp.data;
    });
    let artclIds = ARTICLE_IDENITIFER;
    this.articleIdentifiers =
      artclIds &&
      artclIds.map((elem) => {
        return elem.key;
      });

    let isHintsAlreadyShown = localStorage.getItem("prod_hint_status");
    if (!isHintsAlreadyShown) {
      localStorage.removeItem("prod_hint_status");
      this.isHintActive = true;
    }

    //extract marketplace identifiers
    let mkpIds = MKP_IDENTIFIERS;
    let mkpIdentifiers = mkpIds.filter((elem) => {
      return elem.key === MARKETPLACE_NAME.toLowerCase();
    });
    this.mkpIdentifiers =
      mkpIdentifiers.length > 0 ? mkpIdentifiers[0].identifiers : [];

    this.funnelCards = FUNNEL_CARDS;
    this.navs = NAVS;
    this.searchPlaceholderText = "Search by";
    this.headers = this.modifyHeaders(cloneDeep(HEADERS));

    this.currentHintStage = Number(
      (this.$route.query && this.$route.query.hint) || 0
    );
    
    this.setCurrentHint(this.currentHintStage);
    this.initializeComponent();
  },
  computed: {
    formatLastJobDate() {
      const gmtDateTime = moment.utc(
        this.lastJobData.date_time,
        "YYYY-MM-DD HH:mm:ss"
      );
      return gmtDateTime.local().format("MMM Do YYYY, hh:mm:ss A");
    },
  },
  methods: {
    changePlaceholderText() {
      let placeholderName = this.productIdentifiers.find(
        (item) => item.value === this.productIdentifier
      );
      this.searchPlaceholderText = "Search by " + placeholderName.text;
    },
    getCompanyBasePath,
    initializeComponent() {
      this.pageLoading = true;
      return AdminMarketplacesService.getCompanyStatus()
        .then(({ data }) => {
          this.isActive = data.is_active;
          if (!this.isActive) return;
          return Promise.all([
            AdminMarketplacesService.fetchBrandsByCompany(),
            AdminMarketplacesService.fetchProductStats(),
            this.getMappedSkuList(),
            this.fetchLastJobStats(),
          ]);
        })
        .then((res) => {
          this.pageLoading = false;
          if (!res) return;

          this.lastJobData = res[3] && res[3].data || {};
          if (res[3].data && res[3].data.status == "running") {
            this.pollJobStatus("", this.interval, 0);
          }
          let { brands, stats } = {
            brands: res[0].data.brands,
            stats: res[1].data,
          };
          this.setProdStats(stats);

          this.brands =
            brands &&
            brands.map((brand) => {
              brand.text = brand.name;
              return brand;
            });
          if (this.brands.length) {
            this.selectedBrand = this.brands[0].value;
            this.brandValuesList = this.brands;
          }
          this.brandValuesList = [
            {
              text: "All",
              value: [],
            },
            ...this.brandValuesList,
          ];
        })
        .catch(() => {
          this.pageLoading = false;
          this.$snackbar.global.showError("Failed to load products");
        });
    },
    setCurrentHint(currentHintStage) {
      if (currentHintStage == 0) {
        this.doneToNext = true;
      }
      this.hintSteps =
        currentHintStage == 0
          ? [
              {
                title: "1. Let’s map your first product",
                element: "#edit-product",
                intro: "Click this “Edit” icon",
                skipLabel: "x",
                hidePrev: true,
                position: "left",
              },
            ]
          : [
              {
                title: "Great Job! What’s more?",
                element: "#MerchantSKU",
                intro:
                  "You have mapped your first product! You can map products in bulk by using the Download and Upload feature. Also you can always replay this tutorial using the above context menu.",
                position: "left",
              },
            ];
    },
    onDrop(e) {
      this.highlight = false;
      const files = e.dataTransfer.files;
      this.onFileRead(files);
    },
    fetchLastJobStats() {
      return AdminMarketplacesService.fetchProductsLastJobStatus();
    },
    editProduct(product) {
      this.$router.push({
        name: "edit_product",
        params: {
          productData: product,
        },
      });
    },
    hintChanged() {
      this.isHintActive = !this.isHintActive;
      this.currentHintStage = 0;
      let isHintsAlreadyShown = localStorage.getItem("prod_hint_status");
      if (isHintsAlreadyShown) {
        localStorage.removeItem("prod_hint_status");
      } else if (!this.isHintActive) {
        localStorage.setItem("prod_hint_status", "shown");
      }
      this.setCurrentHint(this.currentHintStage);
      document.cookie =
        "introjs-dontShowAgain" +
        "=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    setHintInactive() {
      document.cookie =
        "introjs-dontShowAgain" +
        "=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.isHintActive = false;
    },
    handleHintFinished() {
      localStorage.setItem("prod_hint_status", "shown");
      this.isHintActive = false;
    },
    showMoreProducts(productIndex) {
      this.showAllProducts[productIndex] = !this.showAllProducts[productIndex];
      this.$forceUpdate();
    },
    calculateSkuCount(product, type = "mapped") {
      if (!product.articles.length) {
        return 0;
      }
      let count = 0;
      product.articles.forEach((prod) => {
        if (
          (type == "mapped" &&
          prod.marketplace_identifiers &&
          prod.marketplace_identifiers.sku) || (type == "active" && prod.active)
        ) {
          count++;
        }
      });
      return count;
    },
    showArticles(product, idx) {
      if (!this.showAllProducts[idx]) {
        return product.slice(0, 2);
      }
      return product;
    },
    modifyHeaders(headers) {
      let mkpStoreIdIndex = headers.findIndex(
        (header) => header.title === `${MARKETPLACE_NAME} SKU`
      );
      headers.splice(mkpStoreIdIndex, 1);
      // put actual marketplace headers from extra headers map
      headers.splice(mkpStoreIdIndex, 0, ...EXTRA_HEADERS_FIELDS.extraFields);

      return headers;
    },
    setProdStats(prodStats) {
      this.statsData = cloneDeep(prodStats);
      for (let idx = 0; idx < this.funnelCards.length; idx++) {
        if (this.funnelCards[idx].type == "stats") {
          let previousModel = this.funnelCards[idx - 1].model;
          let nextModel = this.funnelCards[idx + 1].model;
          console.log("modelCount", previousModel, nextModel);
          if (prodStats[previousModel] != 0) {
            this.funnelCards[idx].percentage = (
              ((prodStats[previousModel] - prodStats[nextModel]) * 100) /
              prodStats[previousModel]
            ).toFixed(2);
          } else {
            this.funnelCards[idx].percentage = 0.0;
          }
          if (this.funnelCards[idx].percentage == 0) {
            this.funnelCards[idx].icon = "equal_arrow";
          } else {
            this.funnelCards[idx].icon = "bottom_arrow";
          }
        }
      }
    },
    cancelUploadProductMapping() {
      this.csvError = false;
      this.showCSVPreview = false;
      this.$refs["csv-preview"].removeGrid();
    },
    proceedUploadProductMapping(
    ) {
      this.inProgress = true;
      this.isBulkActionOpen = false;
      this.showCSVPreview = false;
      this.csvError = false;
      this.processSkuBatches().finally(() => {
        this.inProgress = false;
        this.processSkuBatches = "";
      });
    },
    // List, create, edit product mapping
    getMappedSkuList() {
      let query = {};
      if (this.selectedBrandFilter) {
        query["brand_id"] = this.selectedBrandFilter;
      }
      if (this.searchSKUText) {
        query["search"] = this.searchSKUText.trim();
      }
      if (this.selectedStatus != "all") {
        query["is_active"] = this.selectedStatus;
      }
      query["identifierType"] = this.productIdentifier;
      query = {
        ...query,
        limit: this.pagination.limit,
        page: this.pagination.current,
      };
      this.inProgress = true;
      AdminMarketplacesService.getMappedSkuList(query)
        .then(({ data }) => {
          if (!data.data.length) {
            this.skuList = [];
            this.pagination.current = data.page.current;
            this.pagination.total = data.page.total_item_count;
            return;
          }
          this.skuList = data.data;
          this.skuList.forEach(sku => {
            sku.articles.forEach(article => {
                if(!article?.marketplace_identifiers?.vertical){
                    return;
                }
                article.marketplace_identifiers.vertical = this.formatVertical(article.marketplace_identifiers.vertical);
            })
          })
          let noOfProducts = data.data.length;
          this.showAllProducts = new Array(noOfProducts).fill(false);
          this.pagination.current = data.page.current;
          this.pagination.total = data.page.total_item_count;
          this.articleIdfsInItemsList = [];
        })
        .finally(() => {
          this.inProgress = false;
        });
    },
    formatVertical(unformattedVertical){
        return this.verticals.find(vertical => vertical.value == unformattedVertical)?.text || unformattedVertical;
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    handlePrimaryEvt() {
      this.isHintActive = false;
    },
    downloadErrorReport() {
      console.log("this.csverrordata", this.csvErrorData.data);
      //if error is not in first row then errors column is not added, so adding error "" there
      this.csvErrorData.data[0].errors = this.csvErrorData.data[0].errors || "";
      const parsedContent = csvParser.unparse(this.csvErrorData.data);
      const file = new File([parsedContent], "product-mapping-error.csv", {
        type: "data:text/csv;charset=utf-8",
      });
      saveAs(file);
      this.csvErrorData = {};
    },

    // Upload Mapping (Bulk)
    onFileRead(files) {
      parseCsvV1(files, this.onBulkUploadSkus);
    },
    /**
     * article identifiers can be empty and but can not be duplicated
     * marketplace identifiers cannot be empty neighter duplicated
     */
    onBulkUploadSkus(csvData) {
      if (this.$refs && this.$refs.fileUploader)
        this.$refs.fileUploader.value = "";
      if (csvData.meta.fields.indexOf("errors") !== -1) {
        return this.$snackbar.global.showError(
          "Invalid columns, remove error column"
        );
      }
      if (!csvData.data || !csvData.data.length) {
        return this.$snackbar.global.showError(
          "CSV is empty. Please add data to CSV."
        );
      }
      if (
        csvData.meta.fields.indexOf("Seller Identifier") === -1
        // || !headersHaveArid
      ) {
        return this.$snackbar.global.showError(
          "Invalid columns, Required column missing"
        );
      }
      this.inProgress = true;
      this.uploadedCSVData = csvData;
      // making it async, so loader can start before processing start
      setTimeout(this.processUploadSkus, 0);
    },
    resetPagination() {
      this.pagination.current = 1;
      this.pagination.limit = 10;
    },
    isInvalidFileSize(csvData) {
      return csvData.data && csvData.data.length > BATCH_SIZE * 50;
    },
    showFileSizeError() {
      this.inProgress = false;
      return this.$snackbar.global.showError(`Maximum a file can have ${BATCH_SIZE * 50} sku mappings`);
    },
    initializeProcessingVars(csvData) {
      let uniqueIdfs = {};
      csvData.meta.fields.forEach((field) => {
          uniqueIdfs[field] = new Set();
      });
      return { uniqueIdfs, errors: [], mkpMappingPayload: [], errorPayloads: [] };
    },
    shouldSkipIdentifier(identifier, skuMap) {
      return SELLER_IDENTIFIERS.includes(identifier) || ( 
        this.isValidIdentifier(this.articleIdentifiers, identifier) 
        && !skuMap[identifier]);    
    },
    validateVertical({ skuMap, error }) {
      if(!skuMap["Jiomart VERTICAL"] || skuMap["Jiomart VERTICAL"].trim() === ""){
        error = `Please Enter a Vertical`;
      }
      else if(!this.isValidVertical(skuMap["Jiomart VERTICAL"].toLowerCase().replaceAll(" ", "").replaceAll(",", ""))){
        error = `${skuMap["Jiomart VERTICAL"]} is invalid Vertical`;
      }

      return error
    },
    checkDuplicates({ skuMap, uniqueIdfs, identifier, error }) {
      if (
        uniqueIdfs[identifier].has(skuMap[identifier]) ||
        !skuMap[identifier]
      ) {
        error = error + ( skuMap[identifier]
                ? `${identifier}-repeated: ${skuMap[identifier]} `
                : `${identifier}-empty `);
      } else {
        uniqueIdfs[identifier].add(skuMap[identifier]);
      }

      return error
    },
    addMappingToPayload({ skuMap, identifier, payload }) {
      return merge(payload, {
        ...(identifier === "Seller Identifier" && {
          seller_identifier: skuMap[identifier],
        }),
        ...(identifier === `${MARKETPLACE_NAME} SKU` && {
          marketplace_identifiers: {
            sku: skuMap[identifier],
            vertical:  skuMap["Jiomart VERTICAL"]?.toLowerCase().replaceAll(" ", "").replaceAll(",", "")
          },
        }),
        item: {
          item_code: skuMap["Item Code"] ? skuMap["Item Code"] : "",
          uid: skuMap["Item Id"] ? skuMap["Item Id"] : "",
        },
        size: skuMap["Size"] || skuMap["size"],
        brand_uid: skuMap["Brand Id"],
        category: skuMap["Category"],
        season: skuMap["Season"],
        active:
          (skuMap["Status"] &&
          skuMap["Status"].toLowerCase().replace(/↵|\n|\r|\n\r/g, "") ===
            "active"),
      });
    },
    processSkuMap(skuMap, uniqueIdfs) {
        let payload = {};
        let error = "";

        for (const identifier in skuMap) {
          // if identifier is fynd/seller identifier, skip
          if (this.shouldSkipIdentifier(identifier)) {
            continue;
          }

          if(identifier === "Jiomart VERTICAL"){
            error = this.validateVertical({ skuMap, error });
            continue;
          }

          error = this.checkDuplicates({ skuMap, uniqueIdfs, identifier, error })

          if (!error) {
            this.addMappingToPayload({ skuMap, identifier, payload })
          }
        }

        return { payload, error }
    },
    recordErrorOrPayload({ skuMap, payload, mkpMappingPayload, error, errorPayloads }) {
      if (!error) {
        mkpMappingPayload.push(payload);
      } else {
        skuMap.errors = error;
        errorPayloads.push(skuMap);
      }
    },
    processMappings({ csvData,  mkpMappingPayload, errorPayloads, errors, uniqueIdfs }) {
      csvData.data.forEach((skuMap) => {
        const { payload, error } = this.processSkuMap(skuMap, uniqueIdfs);
        errors.push(Object.values(skuMap) + "," + error);
        this.recordErrorOrPayload({ skuMap, payload, mkpMappingPayload, error, errorPayloads }) 
      });
    },
    getCsvDataMeta(csvData) {
      return csvData.meta.fields.map((e) => ({
          headerName: e,
          field: e,
          resizable: true,
        })
      )
    },
    handleCsvMappingErrors({ csvData, errorPayloads, errors }) {
      this.inProgress = false;
      this.csvErrorCount = errorPayloads.length;
      this.csvPreviewTitle = "SKU Mapping CSV Errors Preview";
      this.csvError = true;
      this.showCSVPreview = true;
      this.csvErrorData = csvData;
      this.$snackbar.global.showError("Failed to process sku mapping");
      this.$refs["csv-preview"].createGrid(
        {
          column: this.getCsvDataMeta(csvData),
          rows: this.csvErrorData.data.slice(0, 5),
        },
        { rowClass: "error-row" }
      );
      console.log(
        "error is",
        errorPayloads,
        "csverrordata",
        this.csvErrorData
      );
      // error map will have one row extra, for csv headers
      errors.unshift(csvData.meta.fields.join());
      this.processSkuBatches = () => {
        return new Promise((resolve) => {
          this.downloadCSVFile(errors.join("\n"), "InvalidSKUMapping.csv");
          resolve();
        });
      };
    },
    handleCsvSuccessMapping({ csvData, mkpMappingPayload , errorPayloads }) {
      this.csvPreviewTitle = "SKU Mapping CSV Preview";
      this.processSkuBatches = () => {
        let totalJobs = Math.ceil(mkpMappingPayload.length / 1000);
        mkpMappingPayload = chunk(mkpMappingPayload, 1000);
        return AdminMarketplacesService.uploadBulkSKUMapping({
          payload: mkpMappingPayload[0],
          meta: {
            total_jobs: totalJobs,
          },
        }).then(async (res) => {
          let parentTaskId = res.data.task_id;
          this.jobId = res.data.task_id;
          this.pollJobStatus(parentTaskId, this.interval, 0);
          let PromiseArr = [];
          for(let [payloadIndex, mappingPayload] of mkpMappingPayload.entries()){
            if (payloadIndex == 0) {
                continue;
            }
            const pr = AdminMarketplacesService.uploadBulkSKUMapping({
              payload: mappingPayload,
              meta: {
                parent_task_id: parentTaskId,
              },
            });
            PromiseArr.push(pr);
        
          }

          await Promise.all(PromiseArr);
        });
      };

      if (!errorPayloads.length) csvData.meta.fields.pop();

      this.csvPreviewTitle = "Product Mapping CSV Preview";
      this.csvError = !!errorPayloads.length;
      this.showCSVPreview = true;
      let previewData = csvData.data.slice(0, 5);
      this.$refs["csv-preview"].createGrid(
        {
          column: this.getCsvDataMeta(csvData),
          rows: previewData,
        },
        { rowClass: errorPayloads.length ? "error-row" : "" }
      );
      this.inProgress = false;
    },
    processUploadSkus() {
      let csvData = this.uploadedCSVData;
      if (this.isInvalidFileSize(csvData)) {
        return this.showFileSizeError();
      }

      csvData.meta.fields.push("errors");
      let { mkpMappingPayload, errorPayloads, errors, uniqueIdfs } = this.initializeProcessingVars(csvData);

      // iterate over all mapping, add excat error on duplicates and also form payload
      this.processMappings({ csvData,  mkpMappingPayload, errorPayloads, errors, uniqueIdfs });

      // it there is error
      if (errorPayloads.length) {
        this.handleCsvMappingErrors({ csvData, errorPayloads, errors });
      } else {
        this.handleCsvSuccessMapping({ csvData, mkpMappingPayload , errorPayloads })
      }
    },
    isValidVertical(vertical){
        return (this.verticals.findIndex(el=>el.value == vertical)) > -1;
    },
    isValidIdentifier(allIdentifiers, identifier) {
      return allIdentifiers.indexOf(identifier) !== -1;
    },
    uploadMapping(mappingData) {
      return AdminMarketplacesService.uploadBulkSKUMapping(mappingData).then(
        (data) => {
          this.pollJobStatus(data.task_id, this.interval, 0);
          this.loaderHelperText =
            "SKU mapping getting uploaded, please wait...";
        }
      );
    },
    cancelJobs() {
      console.log("Parent Job");
      AdminMarketplacesService.productCancelJobs({ jobId: this.jobId })
        .then((resp) => {
          let data = resp.data;
          this.lastJobData = data || {};
          this.$snackbar.global.showInfo(data.message);
          this.inProgress = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Download mapping
    onDownloadCsv() {
      this.$refs["sku-download-filter-diag"].open({
        width: "530px",
        // height: '334px',
        showCloseButton: false,
        dismissible: false,
        neutralButtonLabel: "",
        positiveButtonLabel: "", //this.rightButton
      });
    },
    downloadSKUMapping() {
      let query = {
        ...(!isArray(this.selectedBrandForDownload) && {
          brand_id: this.selectedBrandForDownload,
        }),
        ...(this.selectedStatusForDownlaod != "all" && {
          is_active: this.selectedStatusForDownlaod,
        }),
      };

      if (this.selectedBrandForDownload === "all") {
        console.log("here");
        delete query.brand_id;
      }
      console.log("query is", query);

      this.inProgress = true;
      AdminMarketplacesService.downloadSKUMapping(query)
        .then(({ data }) => {
          if (data.failure_message) {
            this.inProgress = false;
            return this.$snackbar.global.showError(data.failure_message);
          }
          this.pollJobStatus(data.task_id, this.interval, 0);
          this.jobId = data.task_id;
          this.loaderHelperText =
            "SKU mapping getting downloaded, please wait...";
          this.$snackbar.global.showSuccess(
            "SKU mapping getting downloaded, please wait..."
          );
          this.isBulkActionOpen = false;
          this.showCSVPreview = false;
        })
        .catch((err) => {
          this.onDownloadCsv();
          this.inProgress = false;
          this.$snackbar.global.showError(
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
              (err && err.message) ||
              "Failed to download sku mapping"
          );
        });
    },

    pollJobStatus(jobIdData, interval, count) {
      this.inProgress = false;
      if (count === MAX_POLLING_COUNTER) {
        this.inProgress = false;
        this.loaderHelperText = "";
        return;
      }
      if (count === 5) interval = 20000;

      this.downloadJobPollId = setTimeout(() => {
        this.fetchLastJobStats()
          .then(({ data }) => {
            console.log("last job data", data);
            this.lastJobData = data;
            console.log("data status", data.status);
            if (data.status == "running") {
              this.jobId = data.task_id;
              console.log("job still running", data);
              return this.pollJobStatus(jobIdData, interval, ++count);
            }
            console.log("job finished", data);
            // adding stats api
            AdminMarketplacesService.fetchProductStats()
              .then((resp) => {
                let stats = resp.data;
                this.setProdStats(stats);
              })
              .catch((err) => {
                console.log(err);
              });
            return this.getMappedSkuList();
          })
          .catch(() => {
            this.$snackbar.global.showError("Downloading failed");
            this.inProgress = false;
          });
      }, interval);
    },
    refreshPage() {
      this.$router.go();
    },
    downloadCSVFile(csvString, fileName, fileLink = "") {
      if (!isEmpty(csvString) || !isEmpty(fileLink)) {
        let link = document.createElement("a");
        link.href = csvString
          ? "data:attachment/csv," + encodeURI(csvString)
          : fileLink;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    clearBrandFilter() {
      this.selectedBrandFilter = "";
    },
    setBrandValuesList(e = {}) {
      if (!e.text) {
        this.clearBrandFilter();
        this.getMappedSkuList();
      }
      this.brandValuesList = [
        {
          text: "All",
          value: [],
        },
      ];
      this.brands.forEach((b) => {
        if (
          !e ||
          !e.text ||
          b.name.toLowerCase().indexOf(e.text.toLowerCase()) > -1
        ) {
          this.brandValuesList.push({
            text: b.text,
            value: b.value,
          });
        }
      });
    },
    clearSearchFilter() {
      this.searchSKUText = "";
    },
    debouncedSearch: debounce(function () {
      if (this.searchSKUText.length === 0) {
        this.clearSearchFilter();
      }
      this.resetPagination();
      this.getMappedSkuList();
    }, 300),
  },
  destroyed() {
    EXT.destroy();
    clearTimeout(this.downloadJobPollId);
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
@import "../less/main.less";

p {
  margin: unset;
}

.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}

.funnel-container {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid #e0e0e0;

  .funnel-top-span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .funnel-head {
      width: 80%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      // line-height: 28px;
      /* identical to box height, or 156% */

      /* Text/primary */

      color: #41434c;
    }

    .funnel-sm-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }

    .funnel-sm-bold-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-align: right;

      /* Text/primary */

      color: #41434c;
    }
  }

  .funnel-cards {
    display: flex;
    align-items: center;
    margin: 24px 0px 0px;

    .funnel-card {
      padding: 24px;
      //   opacity: 0.1;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      width: 18%;
      margin-right: 24px;

      .funnel-card-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 12px;
        /* identical to box height, or 150% */

        /* Text/secondary */

        color: #666666;
      }

      .funnel-card-model {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height, or 120% */

        /* Text/primary */

        color: #41434c;
      }
    }

    .funnel-card-active {
      background-color: #e7eeff;
    }

    .funnel-stats {
      margin-right: 24px;

      .funnel-stat-icon-block {
        display: flex;
        justify-content: space-between;
      }

      .funnel-stat-percentage {
        font-family: Inter, sans-serif;
        font-style: normal;
        margin-left: 12px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Gray 3 */

        color: #828282;
      }
    }
  }
}

.search {
  // margin-top: 20px;
  margin-right: 15px;
  width: 350px;
}

.row-container {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  border-top: none;
  margin-bottom: 12px;

  .row {
    display: flex;
    padding: 12px 0px;
    margin: 0px 24px;
    border-bottom: 1px solid #e0e0e0;

    .product-detail-block {
      flex-basis: 25%;
      margin-bottom: 12px;

      .image-name-block {
        display: flex;
        margin-bottom: 12px;

        .image {
          margin-bottom: 6px;

          .prod-image {
            height: 40px;
            width: 40px;
            object-fit: contain;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
          }
        }

        // width: 25%;
      }

      .mapped-active-sku-block {
        display: flex;

        .sku-count-block {
          background: #e7eeff;
          border-radius: 4px;
          padding: 4px 8px;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
        }

        .active-count-block {
          margin-left: 15px;
        }
      }
    }

    .sku-map-block {
      margin: 0px;
      flex-basis: 75%;
    }

    .name-block {
      margin-left: 20px;

      .product-name {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height, or 142% */

        /* Text/primary */

        color: #41434c;
      }

      .product-brand-name {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height, or 142% */

        /* Text/secondary */

        color: #666666;
      }
    }

    .sku-row {
      display: flex;
      justify-content: space-between;
    }

    .row-value {
      display: flex;
      align-items: center;
      flex: 0.2;
      padding: 15px 10px;

      .text-overflow {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height, or 142% */

        /* Text/secondary */

        color: #666666;
      }

      .hint-block {
        display: flex;
        justify-content: space-around;

        :deep(.nitrozen-tooltip-left) {
          top: -55px;
          right: 30px;
        }
      }

      .hint-block-last {
        display: flex;
        justify-content: space-around;

        :deep(.nitrozen-tooltip-left) {
          top: -110px;
          right: 30px;
        }
      }

      .hint-element {
        // margin: 25px 10px;
        background-color: white;
        z-index: 1000;
        padding: 8px;
        border-radius: 4px;
        position: relative;
        display: block;
      }

      .hint-element-last {
        // margin: 25px 10px;
        background-color: white;
        z-index: 1000;
        padding: 12px;
        border-radius: 4px;
        position: relative;
        display: block;
      }
    }

    .edit-icon {
      cursor: pointer;
      display: inline;

      :deep(.inline-svg) {
        display: inline;
      }
    }

    .show-more-icon {
      display: inline;
      margin-left: 24px;
      cursor: pointer;

      :deep(.inline-svg) {
        display: inline;
      }
    }

    .location-code {
      min-width: 145px;
    }

    .input-error {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 10px;
      padding-bottom: 0;
    }

    .handle {
      width: 130px !important;
    }

    .input-mkp-store-id {
      display: flex;
      // flex-direction: column;
    }
  }
}

.hint-element {
  // margin: 25px 10px;
  background-color: white;
  z-index: 1000;
  padding: 8px;
  border-radius: 4px;
  position: relative;
}

.table-container-bulk-action {
  background: #e7eeff;
  border-radius: 4px;
  padding: 24px;

  .bulk-action-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height, or 140% */

    color: #41434c;
  }

  .bulk-action-sub-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */

    /* Text/secondary */

    color: #666666;
  }

  .bulk-action-cards {
    display: flex;
    justify-content: space-between;

    .bulk-action-card {
      border: 1px dashed #2e31be;
      border-radius: 4px;
      padding: 24px;
      flex-basis: 25%;
      position: relative;
    }

    .bulk-action-card-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      // font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 24px;
      /* or 146% */

      /* Text/primary */

      color: #41434c;
    }

    .bulk-action-card-desc {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 37px;
      margin-top: 24px;
      /* or 308% */

      /* Text/secondary */

      color: #666666;
    }

    .bulk-action-card-btn {
      left: 50%;
      margin-left: none;
      position: relative;
      transform: translate(-50%);
      margin-top: 20px;
    }

    .drag-drop-block {
      .drag-drop-text {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        text-align: center;

        color: #666666;

        margin: 6px;
      }
    }

    input[type="file"] {
      display: none;
    }

    .bulk-update-card-btn {
      left: 50%;
      margin-left: none;
      position: relative;
      transform: translate(-50%);
    }
  }
}

.stats {
  margin: 24px;
}

.back-btn {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.item-mapping {
  justify-content: space-between;
  display: flex;
  width: 100%;

  .dupli-helper-box {
    width: 60%;
  }

  .duplicate-label {
    padding-top: 5px;
    color: @Required;
    line-height: 1.3;
  }
}

:deep(.add-product svg) {
  cursor: pointer;
  width: 38px;
  margin-left: 12px;
  margin-right: 50px;
  height: 38px;
  border: 1px solid @Iron;
  border-radius: 3px;

  #prefix__Group {
    stroke: @RoyalBlue;
  }
}

.action-btn {
  margin-top: 10px;
  margin-left: 20px;
  float: right;
}


.loading {
  z-index: 1000;
}

.download-sku-btn {
  margin-right: 20px;
}

.product-no-content {
  :deep(img) {
    width: 250px;
    height: 200px;
  }
}

.headers {
  display: flex;
  background-color: #f8f8f8;
  border: 3px;
  padding: 0px 24px;
  justify-content: space-between;

  .header {
    padding: 15px 10px;
    text-overflow: ellipsis;
    flex: 0.2;
    // white-space: nowrap;
    // overflow: hidden;
    margin-right: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */

    /* Text/primary */

    color: #41434c;
  }

  .sync-col-width {
    // flex: 0.1;
    display: flex;
  }

  .location-code {
    min-width: 145px;
  }

  .product-details {
    // width: 25%;
    align-self: center;
    flex-basis: 25%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */

    /* Text/primary */

    color: #41434c;
  }

  .sku-section {
    display: flex;
    flex-basis: 75%;
    justify-content: space-between;
  }
}

.product-card {
  padding: 10px;
  border: 1px solid @Iron;
  display: flex;
  margin: 15px 0px;

  .details {
    flex: 1;
    margin: 15px;
    line-height: 1.5;
  }

  .add-btn {
    margin: auto 0px;
    margin-right: 15px;
  }

  .image {
    flex: 0.15;

    img {
      height: 40px;
      width: 40px;
      object-fit: contain;
      margin: 0px 5px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }

    .prod-image {
      height: 40px;
      width: 40px;
      object-fit: contain;
      margin: 0px 5px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin: 0px 5px;
    border: 1px solid #e0e0e0;
  }
}

.sku-download-filter-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: unset;
  }
}

.btns {
  input[type="file"] {
    display: none;
  }
}

.page-header-position {
  height: 64px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  z-index: 1;
}

.date {
  display: flex;
  margin: 24px 0px;

  .date-picker {
    width: 50%;

    :deep(label > span) {
      font-size: 12px;
    }
  }

  .spacer {
    margin-right: 20px;
  }
}

.disable-notice {
  background: @LinkWater;
  height: 48px;
  line-height: 48px;
  padding: 0px 12px 0px 24px;
  border-radius: 4px;
  width: 100%;
}

.disable {
  opacity: 0.4;
  pointer-events: none;
}

.desc {
  margin-top: 10px;
  line-height: 1.6;
}

.card {
  padding: 15px 10px 10px;
  border: 1px solid @Iron;
  margin: 20px 0px;
}

.meta {
  display: flex;
}

.prod-meta {
  display: flex;
  justify-content: space-between;

  img {
    width: 90px;
    height: 100px;
    margin: 0px 15px;
  }

  button {
    margin-right: 15px;
  }
}

.icon1 {
  flex: 0.1;
  margin: 10px 12px;
  display: flex;
  align-items: center;
}

.svg {
  :deep(svg) {
    width: 24px;
    height: 24px;
  }
}

.ids {
  display: flex;
  margin-left: 20px;

  .col {
    flex: 0.2;
    margin: 5px 12px;

    :deep(svg) {
      width: 24px;
      height: 24px;
    }

    :deep(.inline-svg) {
      justify-content: unset;
    }
  }
}

.pad-right {
  margin: 30px;
  // margin-right: 16px;
  float: right;
}

.input-box {
  // width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
  background-color: #f8f8f8;
  border-radius: 4px;
  // border: 1px solid #e0e0e0;
  padding: 12px;

  :deep(.nitrozen-dropdown-container) {
    flex-direction: row;
  }

  .status-filter {
    :deep(.nitrozen-dropdown-label) {
      margin: 12px;
    }

    :deep(.nitrozen-select-wrapper) {
      min-width: 200px;
    }

    :deep(.nitrozen-select__trigger) {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 23px;
      color: #41434c;
      opacity: 0.8;
    }
  }

  .btns-filters {
    display: flex;
    flex-wrap: wrap;

    input[type="file"] {
      display: none;
    }
  }
}

.table-container {
  padding: 24px;
  margin: 24px;
  background-color: White;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;

  .product-map-txt {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0px;
    /* identical to box height, or 140% */

    color: #41434c;
  }

  .product-map-desc {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    margin: 6px 0px;
    /* identical to box height, or 142% */

    /* Text/secondary */

    color: #666666;
  }

  .table-container-top {
    width: 100%;
    display: flex;

    // align-items: baseline;
    .bulk-action-btn {
      margin-left: auto;
    }

    .bulk-action-bg {
      background: #e7eeff;
      border-radius: 4px;
      padding: 12px;
    }
  }

  .preview-block {
    margin-top: 30px;

    .preview-block-top {
      display: flex;
      justify-content: space-between;

      .preview-block-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        /* identical to box height, or 140% */

        color: #41434c;
      }

      .cancel-map-btn {
        margin-left: auto;
      }

      .proceed-map-btn {
        margin-left: 24px;
      }
    }

    .preview-strip-error {
      background: #ffe7e7;
      border: 1px solid #eb5757;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      align-items: center;
      margin: 12px 0px;

      .preview-error-ico {
        margin-right: 6px;
      }

      .preview-error-txt {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        /* identical to box height, or 162% */

        /* Text/primary */

        color: #41434c;
        margin-right: auto;
        margin-left: 6px;
      }

      .preview-error-btn {
        background: #e8505b;
        border-radius: 4px;
      }
    }

    .csv-preview-diag {
      :deep(.nitrozen-dialog-body) {
        overflow: auto;
        max-height: 600px;
      }
    }
  }
}

.tab-content {
  padding: 24px 0px;
  // margin: 24px;
}

.settings {
  :deep(.left-container) {
    background-color: @White;
    padding: 24px;
    width: 60%;
  }

  :deep(.right-container) {
    background-color: @White;
    padding: 24px;
    width: 40%;
  }
}

.search-box {
  margin: 0px 20px 0px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media @mobile {
    display: block;
    margin: 24px 0;
  }

  .search {
    min-width: 400px;

    @media @mobile {
      min-width: 95%;
      width: auto;
    }
  }

  .filter {
    display: flex;

    @media @mobile {
      display: block;
    }
  }

  .label {
    display: flex;
    align-items: center;
    font-family: Inter, sans-serif;
    color: @Mako;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    // margin-top: 30px;
    @media @mobile {
      display: none;
    }
  }

  .filter-dropdown {
    width: 170px;
    margin-left: 12px;

    @media @mobile {
      margin-left: 0;
      width: 100%;
    }
  }
}

.uploadInput {
  visibility: hidden;
}

.back-icon {
  cursor: pointer;
  transform: rotate(90deg);
}

.page_body {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  .mirage-scrollbar;
  // overflow: scroll;
}

.successInJob {
  height: 48px;
  display: flex;
  align-items: center;
  background: #e9f5ee;
  padding: 0 0 0 20px;
  border-radius: 4px;

  .download-lastJob-csv {
    margin-left: 3px;
    margin-right: 12px;
    font-size: 13px;
    color: #2e31be !important;
    cursor: pointer;
  }
}

.errorInJob {
  height: 48px;
  display: flex;
  align-items: center;
  background: #ffe7e7;
  padding: 0 0 0 20px;
  border-radius: 4px;

  .download-failedJob-csv {
    margin-left: 3px;
    margin-right: 12px;
    font-size: 13px;
    color: rgb(232, 80, 91);

    cursor: pointer;
  }
}

.processingInJob {
  height: 48px;
  display: flex;
  align-items: center;
  // background: lavender;
  background-color: #e7eeff;
  padding: 0 0 0 20px;
  border-radius: 4px;
}

.error-btn {
  margin-left: auto;
  margin-right: 10px;
}

.strip-message {
  margin-left: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #41434c;
}

.prod-download-success {
  margin-left: auto;
  margin-right: 10px;
}

.running-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid blue;
  // border-bottom: 4px solid blue;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
